'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var configure = require('../lib/configure.js');
var toUrlSearchParams = require('../lib/to-url-search-params.js');
var multipartRequest = require('ipfs-core-utils/multipart-request');
var abortSignal = require('../lib/abort-signal.js');
var nativeAbortController = require('native-abort-controller');
var toString = require('uint8arrays/to-string');
var mapEvent = require('./map-event.js');

const createPut = configure.configure(api => {
  async function* put(key, value, options = {}) {
    const controller = new nativeAbortController.AbortController();
    const signal = abortSignal.abortSignal(controller.signal, options.signal);
    const res = await api.post('dht/put', {
      signal,
      searchParams: toUrlSearchParams.toUrlSearchParams({
        arg: key instanceof Uint8Array ? toString.toString(key) : key.toString(),
        ...options
      }),
      ...await multipartRequest.multipartRequest([value], controller, options.headers)
    });
    for await (const event of res.ndjson()) {
      yield mapEvent.mapEvent(event);
    }
  }
  return put;
});

exports.createPut = createPut;
