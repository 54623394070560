'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var cid = require('multiformats/cid');
var multipartRequest = require('ipfs-core-utils/multipart-request');
var configure = require('../../lib/configure.js');
var toUrlSearchParams = require('../../lib/to-url-search-params.js');
var abortSignal = require('../../lib/abort-signal.js');
var nativeAbortController = require('native-abort-controller');

const createAppendData = configure.configure(api => {
  async function appendData(cid$1, data, options = {}) {
    const controller = new nativeAbortController.AbortController();
    const signal = abortSignal.abortSignal(controller.signal, options.signal);
    const res = await api.post('object/patch/append-data', {
      signal,
      searchParams: toUrlSearchParams.toUrlSearchParams({
        arg: `${ cid$1 }`,
        ...options
      }),
      ...await multipartRequest.multipartRequest([data], controller, options.headers)
    });
    const {Hash} = await res.json();
    return cid.CID.parse(Hash);
  }
  return appendData;
});

exports.createAppendData = createAppendData;
