'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var modeToString = require('../lib/mode-to-string.js');
var parseMtime = require('../lib/parse-mtime.js');
var configure = require('../lib/configure.js');
var multipartRequest = require('ipfs-core-utils/multipart-request');
var toUrlSearchParams = require('../lib/to-url-search-params.js');
var abortSignal = require('../lib/abort-signal.js');
var nativeAbortController = require('native-abort-controller');

const createWrite = configure.configure(api => {
  async function write(path, input, options = {}) {
    const controller = new nativeAbortController.AbortController();
    const signal = abortSignal.abortSignal(controller.signal, options.signal);
    const res = await api.post('files/write', {
      signal,
      searchParams: toUrlSearchParams.toUrlSearchParams({
        arg: path,
        streamChannels: true,
        count: options.length,
        ...options
      }),
      ...await multipartRequest.multipartRequest([{
          content: input,
          path: 'arg',
          mode: modeToString.modeToString(options.mode),
          mtime: parseMtime.parseMtime(options.mtime)
        }], controller, options.headers)
    });
    await res.text();
  }
  return write;
});

exports.createWrite = createWrite;
